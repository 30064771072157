@tailwind base;

a {
  @apply text-blue-700;
}

a:hover {
  @apply border-b-2 border-blue-700;
}

@tailwind components;

@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl;
    @apply font-bold;
  }
  h2 {
    @apply text-2xl;
    @apply font-bold;
  }
  h3 {
    @apply text-xl;
  }
}

.content article p {
  @apply my-6;
}

.content article ul {
  @apply my-6;
}
